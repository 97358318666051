<template>
  <!-- FIDELTY CARD NUMBERE -->
  <v-form class="d-flex flex-wrap" ref="form" @submit.prevent v-model="isValid">
    <v-row>
      <!-- CARTA FEDELTA -->
      <v-col cols="12" md="5" v-if="isRegisterWithCard">
        <label
          v-html="
            `${$t('register.card.step-0.placeholder.codiceCartaFedeltà')} *`
          "
        />
        <v-text-field
          :placeholder="
            `${$t('register.card.step-0.placeholder.codiceCartaFedeltà')} *`
          "
          :hint="$t('register.card.step-0.placeholder.ccfHint')"
          :rules="[requiredRules()]"
          class="mt-2"
          required
          outlined
          dense
          type="number"
          :disabled="isFieldDisabled"
          v-model="userData.fidelityCard"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="askFirstName">
        <label v-html="`${$t('register.card.step-0.placeholder.sign')} **`" />
        <v-text-field
          :placeholder="`${$t('register.card.step-0.placeholder.sign')}`"
          :hint="$t('register.card.step-0.placeholder.inputHint')"
          class="mt-2"
          required
          outlined
          dense
          type="text"
          :value="sign"
          :disabled="isFieldDisabled"
        />
      </v-col>
      <!-- NOME DEL UTENTE -->
      <v-col cols="12" md="6" v-if="askFirstName">
        <label v-html="`${$t('register.card.step-0.placeholder.name')} * **`" />
        <v-text-field
          :placeholder="`${$t('register.card.step-0.placeholder.name')} *`"
          :hint="$t('register.card.step-0.placeholder.inputHint')"
          :rules="[requiredRules()]"
          class="mt-2"
          required
          outlined
          dense
          type="text"
          v-model="userData.person.firstName"
          :disabled="isFieldDisabled"
        />
      </v-col>
      <!-- COGNOME DEL UTENTE-->
      <v-col cols="12" md="5" v-if="askLastName">
        <label v-html="`${$t('register.card.step-0.placeholder.surname')} *`" />
        <v-text-field
          :placeholder="`${$t('register.card.step-0.placeholder.surname')} *`"
          :hint="$t('register.card.step-0.placeholder.inputHint')"
          :rules="[requiredRules()]"
          class="mt-2"
          required
          outlined
          dense
          type="text"
          v-model="userData.person.lastName"
          :disabled="isFieldDisabled"
        />
      </v-col>
      <!-- DATE DI NASCITA DEL UTENTE-->
      <!-- <v-col cols="12" md="5">
        <v-menu
          v-if="birthDataFields || isProfileForm"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <label v-html="`${$t('profile.birthDate')} *`" />
            <v-text-field
              v-model="birthDate"
              :placeholder="`${$t('profile.birthDate')} *`"
              :rules="[requiredRules()]"
              class="mt-2"
              required
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              :disabled="isFieldDisabled"
            >
            </v-text-field>
          </template>
          <v-date-picker
            locale="it-IT"
            ref="picker"
            :active-picker.sync="activePicker"
            v-model="userData.person.birthDate"
            min="1910-01-01"
            :max="$dayjs().format('YYYY-MM-DD')"
            first-day-of-week="1"
            type="date"
            no-title
            @input="onBirthdateInput"
          ></v-date-picker>
        </v-menu>
      </v-col> -->
      <!--FISCAL CODE DEL UTENTE - visible only on profile update-->
      <v-col cols="12" md="5" v-if="showFiscalCode">
        <label v-html="`${$t('profile.fiscalCode')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.fiscalCode')} *`"
          v-model="userData.person.fiscalCode"
          required
          :rules="cfRules"
          class="mt-2"
          outlined
          dense
          @change="validate"
          :disabled="isFieldDisabled"
        ></v-text-field>
      </v-col>
      <!-- CTAs-->
      <div
        class="w-100 d-flex flex-column flex-md-row justify-sm-space-between justify-md-start"
        v-if="showButtons"
      >
        <!--STEP BACK-->
        <v-col md="5">
          <v-btn
            depressed
            block
            outlined
            x-large
            v-html="$t('register.card.step-0.btnBack')"
            @click="back"
            class="mb-5 mb-sm-0"
          />
        </v-col>
        <!--STEP FORWARD-->
        <v-col md="5">
          <v-btn
            depressed
            color="primary"
            block
            x-large
            :disabled="disabledRegistration"
            v-html="$t('register.card.step-0.btnConfirm')"
            @click="submit"
        /></v-col>
      </div>
    </v-row>
  </v-form>
</template>

<script>
import { requiredValue, isCF } from "~/validator/validationRules";
import { getPersonInfo } from "~/service/ebsn";

export default {
  name: "UserDataForm",
  props: {
    userData: {
      type: Object,
      required: true
    },
    supportData: {
      type: Object,
      required: true
    },
    isFieldDisabled: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    showFiscalCode: { type: Boolean, default: false },
    isProfileForm: { type: Boolean, default: false },
    registrationType: { type: String, default: "OldUser" },
    isRegisterWithCard: { type: Boolean, default: false },
    isFiscalCode: { type: Boolean, default: true },
    hasFidelityCard: { type: Boolean, default: false },
    askFirstName: { type: Boolean, default: true },
    askLastName: { type: Boolean, default: true }
  },
  data() {
    return {
      isValid: false,
      menu: false,
      activePicker: null,
      requiredRules: requiredValue,
      dateRules: [requiredValue("Digitare la data di nascita")],
      cfRules: [requiredValue(), isCF()],
      response: {}
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid;
    },
    birthDataFields() {
      return this.isRegisterWithCard ? true : !this.showFiscalCode;
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY");
      }
    },
    sign() {
      return getPersonInfo(this.userData.person, 6);
    }
  },
  methods: {
    onBirthdateInput() {
      this.menu = false;
    },
    back() {
      this.$emit("back");
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate");
          }
        }
      }, 200);
    },
    submit() {
      if (this.isValid) {
        if (
          this.registrationType == "NewUser" &&
          this.showFiscalCode &&
          !this.isRegisterWithCard
        ) {
          this.userData.person.gender = null;
        }
        console.log(this.userData);
        this.$emit("submit", this.userData, this.supportData, this.birthDate);
      }
    }
  },
  created() {
    //  this.loadCountriesList();
    //this.showFiscalCode = this.askFiscalCode;
    /*if (
      this.$route.path === "/registration-card" &&
      !this.userData.fidelityCard
    ) {
      this.back();
    } else {
      this.loadCountriesList();
      this.showFiscalCode = this.askFiscalCode;
    }*/
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
